.App {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.App input, .App button {
  padding: 10px;
  font-size: 16px;
  margin: 10px 0;
}

.App ul {
  list-style: none;
  padding: 0;
}

.App li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}